import React from 'react'

const Footer = () => {
    return (
        <footer>
            <a href="mailto:s.perretta@dorico.cl">s.perretta@dorico.cl</a>
            <h5>
                www.dorico.cl <span>&copy; {new Date().getFullYear()} </span>
            </h5>


        </footer>
    )
}
export default Footer