export const title = [
    {
        id: 1,
        description: "Dórico Capital intermediadores en estructuración de deuda de corto y largo plazo"
    },
    {
        id: 2,
        description: "Nuestros socios cuentan con más de 15 años de experiencia en el sistema financiero"
    },
    {
        id: 3,
        description: "Trabajamos todos los productos e instrumentos desarrollados por las diferentes instituciones tanto bancarias como no bancarias"
    }
]