export const links = [
    {
        id: 1,
        url: 'valor',
        text: 'Propuesta de Valor'
    },
    {
        id: 2,
        url: 'servicios',
        text: 'Servicios y Productos'
    },
    {
        id: 3,
        url: 'contacto',
        text: 'Contacto'
    },

]